<template>
  <div id="app">
    <div class="wrapper">
		<Navbar />
        <Sidebar />
        <div class="main-panel" style="margin-top:50px">
            <div class="page">
                <router-view></router-view>
                <vue-progress-bar></vue-progress-bar>
            </div>
            <Footer />
        </div>        
    </div>
  </div>
  
</template>
<script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>

<script>
import Navbar from './Navbar'
import Footer from './Footer'
import Sidebar from './Sidebar'
// window.jQuery = window.$ = require('jquery');
// require('../../assets/js/myjs')
export default {
    name: "Scaffold",
    components: {Navbar, Footer, Sidebar },
    created(){
      
    },
    // beforeCreate () {
    //   let recaptchaScript = document.createElement('script')
    //   recaptchaScript.setAttribute('src', '../../assets/myjs.js')
    //   ecaptchaScript.async = true
    //   recaptchaScript.defer = true
    //   document.head.appendChild(recaptchaScript)
    // },
};

</script>

<style>
 @import '../../assets/atlantis/css/bootstrap.min.css';
 @import '../../assets/atlantis/css/atlantis.min.css';
 @import '../../assets/atlantis/css/fonts.min.css';
</style>


