<template>
    <div class="content">
        <div class="panel-header bg-primary-gradient">
            <div class="page-inner py-5">
                <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                    <div>
                        <h2 class="text-white pb-2 fw-bold">Category</h2>
                        <h5 class="text-white op-7 mb-2">OceanDev - Cashflow</h5>
                    </div>
                    <div class="ml-md-auto py-2 py-md-0">
                        <a href="#" class="btn btn-white btn-border btn-round mr-2">Manage</a>
                        <router-link :to="{name:'category-create'}" class="btn btn-secondary btn-round">Add Category</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-inner mt--5">
            <div class="card full-height">
                <div class="card-body">
                    <div class="card-title">Overall statistics</div>
                    <div class="card-category">Daily information about statistics in system</div>
                    <div class="d-flex flex-wrap justify-content-around pb-2 pt-4">
                        <data-table
                            :data="data"
                            :columns="columns"
                            @on-table-props-changed="reloadTable"
                            class="table-full">
                        </data-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ButtonEdit from './ButtonEdit.vue';
import ButtonDelete from './ButtonDelete.vue';
export default {
    data() {
        return {
            url: this.$api_url+"category",
            data: {},
            tableProps: {
                search: '',
                length: 10,
                column: 'id',
                dir: 'asc'
            },
            headers : {
                'Authorization': 'Bearer ' + this.$cookies.get("api_token")
            },
            columns: [
                {
                    label: 'ID',
                    name: 'id',
                    orderable: true,
                    transform : function (data) {
                        return data.row + 1;
                    }
                },
                {
                    label: 'Name',
                    name: 'name',
                    orderable: true,
                },
                {
                    label: 'Description',
                    name: 'description',
                    orderable: true,
                },
                {
                    label: '',
                    name: 'Edit',
                    orderable: false,
                    classes: {
                        'btn': true,
                        'btn-primary': true,
                        'btn-sm': true,
                    },
                    event: "click",
                    handler: this.displayRow,
                    component: ButtonEdit,
                },
                {
                label: '',
                    name: 'Delete',
                    orderable: false,
                    classes: {
                        'btn': true,
                        'btn-danger': true,
                        'btn-sm': true,
                    },
                    event: "click",
                    handler: this.delete,
                    component: ButtonDelete,
                },
            ]
        }
    },
    created() {
        this.getData(this.url);
        this.$Progress.start()
    },
    methods: {
        getData(url = this.url, options = this.tableProps) {

            this.axios.get(url, {
                params: options,
                headers : this.headers
            })
            .then(response => {
                this.data = response.data;
                this.$Progress.finish()
                console.log(response);
            })
            // eslint-disable-next-line
            .catch(errors => {
                //Handle Errors
            })
        },
        reloadTable(tableProps) {
            this.$Progress.start()
            this.getData(this.url, tableProps);
            this.$Progress.finish()
        },
        displayRow(data) {
            this.$router.push({
                name: 'category-edit',
                params: {
                    id:data.id
                }
            });
        },
        delete(data){
            let uri = this.url+"/delete/"+data.id;
            // console.log(data.id);
            this.$swal.fire({
                title: 'Apakah kamu yakin?',
                text: "Jika kamu hapus, maka data tidak akan kembali lagi.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Hapus Deh',
                cancelButtonText: 'Nggak Jadi'
                }).then((result) => {
                if (result.value) {
                    this.$Progress.start()
                    this.$swal.fire({
                        title: 'Success!',
                        text: 'Article deleted successfully',
                        icon: 'success',
                        timer: 1000
                    });
                    this.axios.get(uri,{
                        headers : this.headers
                    }).then(response => {
                        this.getData(this.url);
                        this.$Progress.finish()
                        response
                    }).catch(err => {
                        console.log(err);
                    });
                }
            })
        }
    }
}

</script>

