<template>
    <div class="content">
        <div class="panel-header bg-primary-gradient">
            <div class="page-inner py-5">
                <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                    <div>
                        <h2 class="text-white pb-2 fw-bold">Edit Pemasukan</h2>
                        <h5 class="text-white op-7 mb-2">OceanDev - Cashflow</h5>
                    </div>
                    <div class="ml-md-auto py-2 py-md-0">
                        <router-link :to="{name:'dashboard'}" class="btn btn-white btn-border btn-round mr-2">Home</router-link>
                        <router-link :to="{name:'pemasukan'}" class="btn btn-secondary btn-round">Back</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-inner mt--5">
            <div class="card full-height">
                <div class="card-body">
                    <div class="card-title">Edit New Data</div>
                    <div class="card-category">Form for update data</div>
                    <div class="mt-4">
                        <form @submit.prevent="Store">
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Name</label>
                                <input v-model="data.name" type="text" class="form-control">
                                <div v-if="validation.name">
                                    <div class="alert alert-danger mt-1" role="alert">
                                        {{ validation.name[0] }}
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Category</label>
                                <select class="form-control" v-model="data.category_id">
                                    <!-- <template v-for="(data) in category" :key="data.id"> -->
                                        <option v-for="(data) in category" :key="data.id"  v-bind:value="data.id">{{data.name}}</option>
                                    <!-- </template> -->
                                </select>
                                <!-- <input v-model="data.name" type="text" class="form-control"> -->
                                <div v-if="validation.name">
                                    <div class="alert alert-danger mt-1" role="alert">
                                        {{ validation.name[0] }}
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Value</label>
                                <input v-model="data.value" type="number" class="form-control">
                                <div v-if="validation.value">
                                    <div class="alert alert-danger mt-1" role="alert">
                                        {{ validation.value[0] }}
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Date</label>
                                <input v-model="data.date" type="date" class="form-control">
                                <div v-if="validation.date">
                                    <div class="alert alert-danger mt-1" role="alert">
                                        {{ validation.date[0] }}
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputPassword1" class="form-label">Description</label>
                                <textarea v-model="data.description" class="form-control"></textarea>
                            </div>
                            <button type="reset" class="float-right btn btn-danger">Reset</button>
                            <button type="submit" class="float-right btn btn-primary mr-lg-2">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data: {},
                category:{},
                validation: [],
                currentUrl: location.toString(),
                host: this.$api_url,
                headers : {
                    'Authorization': 'Bearer ' + this.$cookies.get("api_token")
                },
            }
        },
        created(){
            this.$Progress.start();
            this.CategorySet();
            let uri = this.host + `pemasukan/edit/${this.$route.params.id}`;
            this.axios.get(uri,{headers:this.headers}).then((response) => {
                this.data = response.data.data;
                console.log(response.data.data);
                this.$Progress.finish()
            });
        },
        methods: {
            Store() {
                this.$Progress.start()
                // let uri = this.host+"/api/category/store";
                let uri = this.host+`pemasukan/update/${this.$route.params.id}`;
                this.axios.post(uri, this.data,{headers:this.headers})
                .then((response) => {
                    this.$router.push({name: 'pemasukan'});
                    this.$swal.fire({
                        title: 'Success!',
                        text: 'Pemasukan update successfully',
                        icon: 'success',
                        timer: 1000
                    });
                    response
                    this.$Progress.finish()
                }).catch(error => {
                    this.validation = error.response.data.data;
                });
            },
            CategorySet(){
                let uri = this.$api_url+"category/search";
                this.axios.get(uri,{headers:this.headers})
                .then(response => {
                    this.category = response.data;
                }).catch(err => {
                    console.log(err);
                })
            }
        }
    }
</script>
