import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import VueAxios from 'vue-axios';
import axios from 'axios';

// import jQuery from 'jquery';
// let $ = jQuery;
// console.log($);

Vue.config.productionTip = false
import router from "./router";

Vue.use(VueRouter)
Vue.use(VueAxios,axios)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
