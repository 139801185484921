<template>
    <div class="content">
        <div class="panel-header bg-primary-gradient">
            <div class="page-inner py-5">
                <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                    <div>
                        <h2 class="text-white pb-2 fw-bold">Create Post</h2>
                        <h5 class="text-white op-7 mb-2">OceanDev - Cashflow</h5>
                    </div>
                    <div class="ml-md-auto py-2 py-md-0">
                        <router-link :to="{name:'dashboard'}" class="btn btn-white btn-border btn-round mr-2">Home</router-link>
                        <router-link :to="{name:'post'}" class="btn btn-secondary btn-round">Back</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-inner mt--5">
            <div class="card full-height">
                <div class="card-body">
                    <div class="card-title">Create New Data</div>
                    <div class="card-category">Form for update data</div>
                    <div class="mt-4">
                        <form @submit.prevent="Store">
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Title</label>
                                <input v-model="data.title" type="text" class="form-control">
                                <div v-if="validation.title">
                                    <div class="alert alert-danger mt-1" role="alert">
                                        {{ validation.title[0] }}
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Author</label>
                                <input v-model="data.author" type="text" class="form-control">
                                <div v-if="validation.author">
                                    <div class="alert alert-danger mt-1" role="alert">
                                        {{ validation.author[0] }}
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Category</label>
                                <input v-model="data.category" type="text" class="form-control">
                                <div v-if="validation.category">
                                    <div class="alert alert-danger mt-1" role="alert">
                                        {{ validation.category[0] }}
                                    </div>
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Image</label>
                                <br>
                                <input type="file" @change="selectFile">
                                <div v-if="validation.category">
                                    <div class="alert alert-danger mt-1" role="alert">
                                        {{ validation.category[0] }}
                                    </div>
                                </div>
                            </div>
                            <button type="reset" class="float-right btn btn-danger">Reset</button>
                            <button type="submit" class="float-right btn btn-primary mr-lg-2">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            data:{},
            photo: null,
            validation:[]
        }
    },
    methods:{
        selectFile(event) {
            // `files` is always an array because the file input may be in multiple mode
            this.photo = event.target.files[0];
            // console.log(this.photo);
        },
        Store(){
            const form = new FormData();
            form.append('image', this.photo);
            form.append('title', this.data.title);
            form.append('author', this.data.author);
            form.append('category', this.data.category);
            console.log(form);

            this.axios.post('http://localhost:2000/api/post/create',form)
            .then(response => {
                console.log(response);
            })
        }
    }
}
</script>


