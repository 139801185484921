<template>
    <div class="content">
        <div class="panel-header bg-primary-gradient">
            <div class="page-inner py-5">
                <div class="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                    <div>
                        <h2 class="text-white pb-2 fw-bold">Post</h2>
                        <h5 class="text-white op-7 mb-2">OceanDev - Cashflow</h5>
                    </div>
                    <div class="ml-md-auto py-2 py-md-0">
                        <a href="#" class="btn btn-white btn-border btn-round mr-2">Manage</a>
                        <router-link :to="{name:'post-create'}" class="btn btn-secondary btn-round">Add Post</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-inner mt--5">
            <div class="card full-height">
                <div class="card-body">
                    <div class="card-title">Overall statistics</div>
                    <div class="card-category">Daily information about statistics in system</div>
                    <div class="d-flex flex-wrap justify-content-around pb-2 pt-4">
                        <div class="table-responsive">
                            <table class="table table-striped table-hover">
                                <th>No</th>
                                <th>Title</th>
                                <th>Author</th>
                                <th>Category</th>
                                <th>Action</th>

                                <tbody>
                                    <tr v-for="(post,index) in data" :key="post.id">
                                        <td>{{index+1}}</td>
                                        <td>{{post.title}}</td>
                                        <td>{{post.author}}</td>
                                        <td>{{post.category}}</td>
                                        <td>
                                            <button class="btn btn-sm btn-warning">Edit</button>
                                            <button class="btn btn-sm btn-danger ml-1">Delete</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- <data-table
                            :data="data"
                            :columns="columns"
                            @on-table-props-changed="reloadTable"
                            class="table-full">
                        </data-table> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            data:{},
            uri: this.$api_url+"post",
            headers : {
                'Authorization': 'Bearer ' + this.$cookies.get("api_token")
            },
        }
    },
    created(){
        this.loadData();
    },
    methods:{
        loadData(){
            this.axios.get(this.uri,{
                headers:this.headers
            }).then(response => {
                this.data = response.data;
                // console.log(this.data);
            }).catch(err => {
                console.log(err);
            })
        }
    }
}
</script>