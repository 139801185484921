<template>
  <div id="app">
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>

  </div>
  
</template>

<script>

export default {
  name: 'App',
  created(){
    this.$Progress.start()
  }
}
</script>

<style>
  @import './assets/css/mycss.css';
</style>


