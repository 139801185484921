<template>
    <div>
        <h1>Testing Testing</h1>
        <h1>Testing Testing</h1>
        <h1>Testing Testing</h1>
        <h1>Testing Testing</h1>
        <h1>Testing Testing</h1>
        <h1>Testing Testing</h1>
        <h1>Testing Testing</h1>
    </div>
</template>

<script>
export default {
    created(){
        this.$Progress.finish()
    }
}
</script>