<template>
    <div class="page">
        <div class="sidenav">
            <div class="login-main-text">
                <h2>
                    Application<br />
                    Login Page
                </h2>
                <p>Login or register from here to access.</p>
            </div>
        </div>
        <div class="main">
            <div class="col-md-12 col-sm-12">
                <div class="login-form">
                    <form @submit.prevent="store">
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" v-model="login.email" class="form-control" placeholder="Email" />
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" v-model="login.password" class="form-control" placeholder="Password" />
                        </div>

                        <button type="submit" class="float-right btn btn-secondary ml-2">Register</button>
                        <button type="submit" class="float-right btn btn-black text-light">Login</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            login:{},
            validation:[]
        }
    },
    created(){
        this.$Progress.finish()
    },
    methods:{
        store(){
        this.$Progress.start()
            // let uri = "http://127.0.0.1:2000/api/login";
            let uri = "https://vue.oceandevapp.com/api/login";
            this.axios.post(uri,this.login)
            .then((result) => {
                let res = result.data.results;

                if (result.data.status == 200) {
                    this.$cookies.set("api_token",res.api_token,"1d");
                    this.$cookies.set("name",res.name,"1d");
                    this.$cookies.set("email",res.email,"1d");
                    this.$router.push({
                        name: 'dashboard'
                    });    
                    let routeData = this.$router.resolve({name: 'dashboard'});
                    // console.log(routeData);
                    window.location = routeData.href;
                } else {
                    alert("Invalid Data")
                    this.$Progress.fail()
                }
                
            }).catch((err) => {
                console.log(err);
            });
        }
    }
}
</script>