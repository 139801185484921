<template>
<div class="sidebar sidebar-style-2">
    <div class="sidebar-wrapper scrollbar scrollbar-inner">
        <div class="sidebar-content">
            <div class="user">
                <div class="avatar-sm float-left mr-2">
                    <img src="@/assets/atlantis/img/profile.jpg" alt="..." class="avatar-img rounded-circle">
                </div>
                <div class="info">
                    <a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
                        <span>
                            {{name}}
                            <span class="user-level">Administrator</span>
                            <span class="caret"></span>
                        </span>
                    </a>
                    <div class="clearfix"></div>

                    <div class="collapse in" id="collapseExample">
                        <ul class="nav">
                            <li>
                                <a href="#profile">
                                    <span class="link-collapse">My Profile</span>
                                </a>
                            </li>
                            <li>
                                <a href="#edit">
                                    <span class="link-collapse">Edit Profile</span>
                                </a>
                            </li>
                            <li>
                                <a href="#settings">
                                    <span class="link-collapse">Settings</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <ul class="nav nav-primary">
                <li class="nav-section">
                    <span class="sidebar-mini-icon">
                        <i class="fa fa-ellipsis-h"></i>
                    </span>
                    <h4 class="text-section">Components</h4>
                </li>

                <li class="nav-item">
                    <router-link :to="{name:'dashboard'}">
                        <i class="fas fa-home"></i>
                        <p>Dashboard</p>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{name:'post'}">
                        <i class="fas fa-home"></i>
                        <p>Posts</p>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{name:'category'}">
                        <i class="fas fa-home"></i>
                        <p>Category</p>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{name:'pemasukan'}">
                        <i class="fas fa-home"></i>
                        <p>Pemasukan</p>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{name:'pengeluaran'}">
                        <i class="fas fa-home"></i>
                        <p>Pengeluaran</p>
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return {
            name : "",
            emai : "",
        }
    },
    created(){
        this.check();
    },
    methods:{
        check(){
            let token = this.$cookies.get("api_token");
            let name = this.$cookies.get("name");
            let email = this.$cookies.get("email");
            this.name = name;
            this.email = email;

            if (token == null) {
                this.$router.push({
                    name: 'login'
                });
            }
        }
    }
}
</script>
