import Vue from 'vue'
import Router from "vue-router";
import VueRouter from 'vue-router';
import VueAxios from 'vue-axios';
import axios from 'axios';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueProgressBar from 'vue-progressbar';
import LoadScript from 'vue-plugin-load-script';
import JQuery from 'jquery'
window.$ = JQuery
import DataTable from 'laravel-vue-datatable';
import VueSweetalert2 from 'vue-sweetalert2';
import vSelect from 'vue-select';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-select/dist/vue-select.css';

import Login from './components/Auth/Login.vue'
import Scaffold from './components/Layouts/Scaffold.vue'
import Dashboard from './components/Layouts/Home.vue'
import Test from './components/Layouts/Test.vue'

import Category from './components/Category/Index.vue'
import CategoryCreate from './components/Category/Create.vue'
import CategoryEdit from './components/Category/Edit.vue'

import Pemasukan from './components/Pemasukan/Index.vue';
import PemasukanCreate from './components/Pemasukan/Create.vue';
import PemasukanEdit from './components/Pemasukan/Edit.vue';

import Pengeluaran from './components/Pengeluaran/Index.vue';
import PengeluaranCreate from './components/Pengeluaran/Create.vue';
import PengeluaranEdit from './components/Pengeluaran/Edit.vue';

import Post from './components/Post/Index.vue';
import PostCreate from './components/Post/Create.vue';
// import 'popper.js'

// global.jQuery = require('jquery');
// require('bootstrap');
// require('popper.js');

Vue.use(LoadScript);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Router);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(require('vue-cookies'));
Vue.use(DataTable);
Vue.use(VueSweetalert2);
Vue.component('v-select', vSelect);

Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '2px'
})

// Global var for base ulr
var host = window.location.origin;
var name = window.location.host;
console.log(host);
if (host == "http://localhost:8080" || name == "localhost:8080") {
    // Vue.prototype.$api_url = "http://localhost:2000/api/";
    Vue.prototype.$api_url = "https://vue.oceandevapp.com/api/";
} else {
    Vue.prototype.$api_url = "https://vue.oceandevapp.com/api/";
}

// Vue.loadScript("/assets/js/myjs.js")
export default new Router({
    mode: "history",
    routes: [
        {
            path:"",
            component:Login,
            name : "login"
        },
        {
            path:"/home",
            component:Scaffold,
            children:[
                {
                    path:"/dashboard",
                    component:Dashboard,
                    name:"dashboard"
                },
                {
                    path:"/test/mantap",   
                    component:Test,
                    name:"test"
                },
                {
                    path:"/category",   
                    component:Category,
                    name:"category"
                },
                {
                    path:"/category/create",   
                    component:CategoryCreate,
                    name:"category-create"
                },
                {
                    path:"/category/edit/:id",   
                    component:CategoryEdit,
                    name:"category-edit"
                },
                {
                    path:"/pemasukan",   
                    component:Pemasukan,
                    name:"pemasukan"
                },
                {
                    path:"/pemasukan/create",   
                    component:PemasukanCreate,
                    name:"pemasukan-create"
                },
                {
                    path:"/pemasukan/edit/:id",   
                    component:PemasukanEdit,
                    name:"pemasukan-edit"
                },
                {
                    path:"/pengeluaran",   
                    component:Pengeluaran,
                    name:"pengeluaran"
                },
                {
                    path:"/pengeluaran/create",   
                    component:PengeluaranCreate,
                    name:"pengeluaran-create"
                },
                {
                    path:"/pengeluaran/edit/:id",   
                    component:PengeluaranEdit,
                    name:"pengeluaran-edit"
                },
                {
                    path:"/post",   
                    component:Post,
                    name:"post"
                },
                {
                    path:"/post/create",   
                    component:PostCreate,
                    name:"post-create"
                },
                {
                    path:"/post/edit/:id",   
                    component:PengeluaranEdit,
                    name:"post-edit"
                }
            ]
        }
    ]
});